import React, { Component } from 'react'
import Axios from 'axios'
import { Redirect } from 'react-router-dom'

import Loading from '../../components/loading/'

import MainMenu from '../../menus/main/'
import Client from '../../components/client/'

require('dotenv').config()

class Clients extends Component {
    constructor( ) {
        super()
        this.state = {
            showLoading: false,
            redirect: false,
            clients: [],
        }
    }

    componentDidMount() {
        this.setState({ showLoading: true }, () => {
            if ( localStorage.getItem( 'api_token' ) ) {
                let headers = {
                    headers: {
                        'API-TOKEN' : localStorage.getItem( 'api_token' )
                    }
                }
                Axios.get( process.env.REACT_APP_API_URL + '/api/clients', headers )
                .then( res => {
                    this.setState({ clients: res.data.success, showLoading: false })
                })
                .catch( errors => {
                    if ( errors.response.status === 403 ) {
                        localStorage.setItem( 'api_token', '' )
                        localStorage.clear()
                        this.setState({ redirect: true })
                    } else {
                        console.warn( errors.response )
                    }
                })
            } else {
                this.setState({ redirect: true })
            }
        })
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        return (
            <>
                <MainMenu currentPage="Clients" />
                { this.state.showLoading && <Loading /> }
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <div className="w-100 mt-5">
                            {
                                this.state.clients.map( (client) =>
                                    <Client key="{client.id}" client={client} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Clients
