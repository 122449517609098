import React, { Component } from 'react'
import Axios from 'axios'
import { Redirect, Link } from 'react-router-dom'

import Loading from '../../components/loading/'

import MainMenu from '../../menus/main/'
import Server from '../../components/server/'

import './servers.scss'

require('dotenv').config()

class Servers extends Component {
    constructor( ) {
        super()
        this.state = {
            showLoading: false,
            redirect: false,
            servers: [],
        }
    }

    componentDidMount() {
        this.setState({ showLoading: true }, () => {
            if ( localStorage.getItem( 'api_token' ) ) {
                let headers = {
                    headers: {
                        'API-TOKEN' : localStorage.getItem( 'api_token' )
                    }
                }
                Axios.get( process.env.REACT_APP_API_URL + '/api/servers', headers )
                .then( res => {
                    this.setState({ servers: res.data.success, showLoading: false })
                })
                .catch( errors => {
                    if ( errors.response.status === 403 ) {
                        localStorage.setItem( 'api_token', '' )
                        localStorage.clear()
                        this.setState({ redirect: true })
                    } else {
                        console.warn( errors.response )
                    }
                })
            } else {
                this.setState({ redirect: true })
            }
        })
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        return (
            <>
                <MainMenu currentPage="Servers" />
                { this.state.showLoading && <Loading /> }
                <div className="Servers animate__animated animate__bounceInRight">
                    <div className="container">
                        <Link className="btn btn-primary mb-5" to="/server/new">Ajouter un server</Link>
                        <p>Il y a actuellement {this.state.servers.length} servers actifs</p>
                        {
                            this.state.servers.map( (server) =>
                                <Server key="{server.id}" server={server} />
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default Servers
