import React from 'react'
import { Redirect } from 'react-router-dom'
import Axios from 'axios'

import MainMenu from '../../menus/main/'

import InputText from '../../components/input/text/'
import InputButton from '../../components/input/button/'

require('dotenv').config()

class ProjectAdd extends  React.Component {
    constructor( ) {
        super()
        this.state = {
            name: '',
            description: '',
            codename: '',
            url: '',
            url_to_check: '',
            projectAdded: false,
            errors: [],
            redirect: false,
        }
    }

    componentDidMount() {
        if ( ! localStorage.getItem( 'api_token' ) ) {
            this.setState({ redirect: true })
        }
    }

    handleChangeName = (event) =>  {
        this.setState({ name : event.target.value })
    }
    handleChangeDescription = (event) =>  {
        this.setState({ description : event.target.value })
    }
    handleChangeCodename = (event) =>  {
        this.setState({ codename : event.target.value })
    }
    handleChangeUrl = (event) =>  {
        this.setState({ url : event.target.value })
    }
    handleChangeUrlToCheck = (event) =>  {
        this.setState({ url_to_check : event.target.value })
    }
    handleSubmit = (event) => {
        event.preventDefault()

        let bodyFormData = new FormData();
        bodyFormData.set( 'name' , this.state.name )
        bodyFormData.set( 'description' , this.state.description )
        bodyFormData.set( 'codename' , this.state.codename )
        bodyFormData.set( 'url' , this.state.url )
        bodyFormData.set( 'url_to_check' , this.state.url_to_check )

        let headers = {
            headers: {
                'API-TOKEN' : localStorage.getItem( 'api_token' )
            }
        }

        Axios.post( process.env.REACT_APP_API_URL + '/api/project', bodyFormData, headers )
        .then(res => {
            if ( res.data.success ) {
                this.setState({ projectAdded: true })
            }
        })
        .catch(errors =>  {
            if ( errors.response.status ) {
                this.setState({ errors: errors.response.data.errors }, () => { console.warn( this.state.errors )})
            }
        });
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        if ( this.state.projectAdded ) {
            return ( <Redirect to="/projects" />)
        }
        return (
            <>
                <MainMenu currentPage="Projects" />
                <div className="Projects ProjectAdd animate__animated animate__bounceInRight">
                    <div className="container">
                        <h2 className="my-5">Ajouter un nouveau projet</h2>
                        <form method="POST" onSubmit={this.handleSubmit} className="text-start">
                            <InputText
                                htmlId='ProjectInputName'
                                label='Nom du projet'
                                onChange={this.handleChangeName}
                                errors={ this.state.errors.name }
                            />
                            <InputText
                                htmlId='ProjectInputName'
                                label='Description'
                                onChange={this.handleChangeDescription}
                                errors={ this.state.errors.description }
                            />
                            <InputText
                                htmlId='ProjectInputCodename'
                                label='Codename (nom du dossier du dev)'
                                onChange={this.handleChangeCodename}
                                errors={ this.state.errors.codename }
                            />
                            <InputText
                                htmlId='ProjectInputUrl'
                                label='Url du site'
                                onChange={this.handleChangeUrl}
                                errors={ this.state.errors.url }
                            />
                            <InputText
                                htmlId='ProjectInputUrlToCheck'
                                label='Url à checker'
                                onChange={this.handleChangeUrlToCheck}
                                errors={ this.state.errors.url_to_check }
                            />
                            { this.state.errors && this.state.errors.code === 'bad_credentials' ? <div className="alert alert-danger" >{ this.state.errors.msg }</div> : '' }
                            <InputButton label='Ajouter' />
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default ProjectAdd
