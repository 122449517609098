import React from 'react'

import ProjectInfos from '../projectinfos/'
import Modal from './modal'

const Details = (props) => {
    let { infos, serverInfo, showMore, showMoreInfos } = props
    return (
        <>
            <ProjectInfos infos={infos} serverInfo={serverInfo} />
            { showMore && <Modal infos={infos} serverInfo={serverInfo} showMore={showMore} showMoreInfos={showMoreInfos} /> }
        </>
    )
}

export default Details;
