import React, { Component } from 'react'

import Details from './details.js'
import Arrows from './arrows.js'

import './project.scss'

class Project extends Component {
    constructor( props ) {
        super( props )
        this.state = {
            showMore: false,
            showAll: false
        }
    }

    showMoreInfos = () => {
        this.setState({ showMore: !this.state.showMore })
    }

    render () {
        let CodeName = this.props.project.codename
        let Name = this.props.project.name
        let ProjectInfos = this.props.project.project_infos
        let ProjectUrl = this.props.project.url_to_check
        let ProjectServerName = this.props.project.server.inouit_name
        return (
			<div className="ProjectInfos" >
                <div className="row" >
                    <div className="ProjectInfosTitle col-sm-12">
                        <p className="text-start mt-5">
                            <span>( <a href={ ProjectUrl }> { CodeName }</a> )</span>/<span>{ Name }</span>/<span>{ ProjectServerName }</span>
                        </p>
                    </div>
                    <div className="ProjectInfosContent col-sm-12">
                        {
                            ( ProjectInfos && ProjectInfos.length > 0 ) && ProjectInfos.map( (infos, index ) =>
                                <Details infos={infos} CodeName={CodeName} index={index} key={index} showMoreInfos={this.showMoreInfos} showMore={this.state.showMore} serverInfo={this.props.project.server} />
                            )
                        }
                        {
                            ( ProjectInfos && ProjectInfos.length > 0 ) ? <Arrows showMoreInfos={this.showMoreInfos} showMore={this.state.showMore} /> : <></>
                        }
                    </div>
                </div>
			</div>
        )
    }
}

export default Project
