import React, { Component } from 'react'

// Script Name
// monitoringInouit

// Script Description
// monitoringInouit

// Application Key
// BWxyoXIfJjQkyEN3

// Application Secret
// CjCA2jGjSvoSZyTUDRA8nl9fAHSMNMGE

// Consumer Key
// moVByzu6UmEuxhk19YhXtjH66Y6iFMQ7

class BackupInfos extends Component {
    constructor( props ) {
        super( props )
        this.state = {
            backupType: 'Physique'
        }
        this.ak = "BWxyoXIfJjQkyEN3"
        this.as = "CjCA2jGjSvoSZyTUDRA8nl9fAHSMNMGE"
        this.ck = "moVByzu6UmEuxhk19YhXtjH66Y6iFMQ7"
        this.method = "GET"
        this.query = ''
        this.body = ''
        this.tstamp = Date.now()
        this.auth = ''
    }

    componentDidMount() {
        var { serverInfo } = this.props
        this.setState({
            backupType: serverInfo.type
        })
    }

    render() {
        let { index, serverInfo } = this.props
        let BackupsCheckLast = this.props.infos.backups_last_date
        let BackupsCheckLastClass = 'alert-danger'
        let BackupsCheckLastDateSplit = String(BackupsCheckLast).replace( ' ', '' ).split('/')
        var date1 = new Date(BackupsCheckLastDateSplit[2], BackupsCheckLastDateSplit[1], BackupsCheckLastDateSplit[0]);
        var date2 = new Date();
        var diffDays = date2.getDate() - date1.getDate();
        if ( diffDays === 0 || diffDays === 1 ) {
            BackupsCheckLastClass = 'alert-success'
        }
        if ( diffDays > 1 ) {
            BackupsCheckLastClass = 'alert-warning'
        }
        if ( diffDays > 10 ) {
            BackupsCheckLastClass = 'alert-danger'
        }
        if ( index > 0 ) {
            BackupsCheckLastClass = 'alert-info'
        }
        let BackupsCheckNbr = this.props.infos.backups_count
        let BackupsCheckNbrClass = 'alert-danger'
        if ( ( serverInfo.type === 'Physique' && BackupsCheckNbr >= 20 ) || ( serverInfo.type === 'VPS' && BackupsCheckNbr >= 7 ) ) {
            BackupsCheckNbrClass = 'alert-warning'
        }
        if ( ( serverInfo.type === 'Physique' && BackupsCheckNbr >= 30 ) || ( serverInfo.type === 'VPS' && BackupsCheckNbr >= 15 ) ) {
            BackupsCheckNbrClass = 'alert-success'
        }
        if ( this.state.backupType === 'Physique' || this.state.backupType === 'VPS' ) {
            return (
                <>
                    <p className="backups">
                        <span className={`backup-date text-center alert ${ BackupsCheckLastClass }`}>Bkp date: { BackupsCheckLast }</span>
                        <span className={`backup-nbr text-center alert ${ BackupsCheckNbrClass }`}>nbr: { BackupsCheckNbr }</span>
                    </p>
                </>
            )
        }
        return (<></>)
    }
}

export default BackupInfos;
