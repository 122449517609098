import React from 'react';

const TechnoInfos = ( props ) => {
    const endOfString = ( searchStr, findStr ) => {
        return searchStr.lastIndexOf( findStr ) > 0 ? searchStr.lastIndexOf( findStr ) + findStr.length : -1;
    }

    const countOccurrence = ( givenStr, ch ) => {
        let count = 0;
        for ( let i = 0; i < givenStr.length; i++ ) {
            if ( givenStr.charAt( i ) === ch ) {
                count ++;
            }
        }
        return count
    }

    let TechnoName = props.infos.techno_current_type
    let TechnoCurrentVersion = props.infos.techno_current_version
    let TechnoCurrentVersionMax = props.infos.techno_current_version_max
    let TechnoVersionMax = props.infos.techno_version_max
    let TechnoVersionShow = props.infos.techno_version_show
    let TechnoVersionClass = 'alert-info'

    if ( countOccurrence( TechnoCurrentVersion, '.') < 2 ){
        TechnoCurrentVersion = TechnoCurrentVersion + ".0"
    }
    if ( Number( TechnoCurrentVersion.replaceAll( '.','' ) ) !== Number( TechnoVersionMax.replaceAll( '.','' ) ) ) {
        TechnoVersionClass = 'alert-danger'
    }
    if (
        ( Number( TechnoCurrentVersion.replaceAll( '.','' ) ) === Number( TechnoCurrentVersionMax.replaceAll( '.','' ) ) )
        || (
            Number( TechnoCurrentVersion.slice( 0, endOfString( TechnoCurrentVersion, '.' ) - 1 ).replaceAll( '.','' ) ) === Number( TechnoCurrentVersionMax.slice( 0, endOfString( TechnoCurrentVersionMax, '.' ) - 1 ).replaceAll( '.','' ) )
            && Number( TechnoCurrentVersion.slice( 0, endOfString( TechnoCurrentVersion, '.' ) - 1 ).replaceAll( '.','' ) ) === Number( TechnoVersionMax.slice( 0, endOfString( TechnoVersionMax, '.' ) - 1 ).replaceAll( '.','' ) )
        )
    ) {
        TechnoVersionClass = 'alert-warning'
    }
    if ( Number( TechnoCurrentVersion.replaceAll( '.','' ) ) === Number( TechnoVersionMax.replaceAll( '.','' ) ) ) {
        TechnoVersionClass = 'alert-success'
    }
    if ( TechnoName === 'WP' && TechnoVersionShow !== '?.?.?' ) {
        TechnoVersionClass = 'alert-danger'
    }
    return (
        <p className="technos">
            <span className={`techno text-center alert ${ TechnoVersionClass }`}>{ TechnoName } / { TechnoCurrentVersion } / { TechnoCurrentVersionMax } / { TechnoVersionMax  } / { TechnoVersionShow  }</span>
        </p>
    );
};

export default TechnoInfos
