import React, { Component } from 'react'
import Axios from 'axios'
import { Redirect, Link } from 'react-router-dom'

import Loading from '../../components/loading/'

import MainMenu from '../../menus/main/'
import Project from '../../components/project/'
import ProjectFilters from '../../components/project/filters.js'

import './projects.scss'

require('dotenv').config()

class Projects extends Component {
    constructor( ) {
        super()
        this.state = {
            showLoading: false,
            projects: [],
            redirect: false,
            technosFilter: false,
            gitCheckFilter: false,
            gitTagCheckFilter: false,
            phpCheckFilter: false,
        }
    }

    componentDidMount() {
        this.setState({ showLoading: true }, () => {
            this.launchSearch()
        })
    }

    launchSearch = () => {
        if ( localStorage.getItem( 'api_token' ) ) {
            let headers = {
                headers: {
                    'API-TOKEN' : localStorage.getItem( 'api_token' )
                }
            }
            var endPoint = process.env.REACT_APP_API_URL + '/api/projects'
            var isFirst = true
            if ( this.state.technosFilter ) {
                endPoint = endPoint + ( isFirst ? '?' : '&') + 'techno_id=' + this.state.technosFilter
                isFirst= false
            }
            if ( this.state.phpCheckFilter ) {
                endPoint = endPoint + ( isFirst ? '?' : '&') + 'php_status=KO'
                isFirst= false
            }
            if ( this.state.gitCheckFilter ) {
                endPoint = endPoint + ( isFirst ? '?' : '&') + 'git_status=KO'
                isFirst= false
            }
            if ( this.state.gitTagCheckFilter ) {
                endPoint = endPoint + ( isFirst ? '?' : '&') + 'git_tag_status=KO'
            }
            Axios.get( endPoint, headers )
            .then( res => {
                this.setState({ projects: res.data.success, showLoading: false }, () => {
                    // console.log( this.state.projects, this.state.projects.length )
                })
            })
            .catch( errors => {
                if ( errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirect: true })
                } else {
                    console.warn( errors.response )
                }
            })
        } else {
            this.setState({ redirect: true })
        }
    }

	filtersOnChangeTechnos = (val)  =>  {
        var filter = false
        if ( val && val.value ) {
            filter = val.value
        }
        this.setState({ technosFilter: filter, showLoading: true }, () => {
            this.launchSearch()
        })
	}

    onGitFiltersChange = (event) => {
        this.setState({ gitCheckFilter: !this.state.gitCheckFilter, showLoading: true }, () => {
            this.launchSearch()
        })
    };

    onGitTagFiltersChange = (event) => {
        this.setState({ gitTagCheckFilter: !this.state.gitTagCheckFilter, showLoading: true }, () => {
            this.launchSearch()
        });
    };

    onPhpFiltersChange = (event) => {
        this.setState({ phpCheckFilter: !this.state.phpCheckFilter, showLoading: true }, () => {
            this.launchSearch()
        })
    };

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        return (
            <>
                { this.state.showLoading && <Loading /> }
                <MainMenu currentPage="Projects" />
                <div className="Projects animate__animated animate__bounceInRight">
				    <div className="container">
                        <Link className="btn btn-primary mb-5" to="/project/new">Ajouter un projet</Link>
                        <p>Il y a actuellement {this.state.projects.length > 0 ? this.state.projects.length : 0 } projet(s) en cours de surveillance</p>
                        <ProjectFilters
                            onTechnosFiltersChange={this.filtersOnChangeTechnos}
                            gitCheckFilter={this.state.gitCheckFilter} onGitFiltersChange={this.onGitFiltersChange}
                            gitTagCheckFilter={this.state.gitTagCheckFilter} onGitTagFiltersChange={this.onGitTagFiltersChange}
                            phpCheckFilter={this.state.phpCheckFilter} onPhpFiltersChange={this.onPhpFiltersChange}
                        />
                        {
                            this.state.projects && this.state.projects.length > 0 && this.state.projects.map( (project, index) =>
                                <Project
                                    project={project} key={index}
                                    gitCheckFilter={this.state.gitCheckFilter}
                                    gitTagCheckFilter={this.state.gitTagCheckFilter}
                                />
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default Projects
