import React from 'react'

const Client = (props) => {
    let Id = props.client.id
    let Name = props.client.name
    let Description = props.client.description
    return (
        <div className="w-100 m-0 mb-4 p-0">
            <div className="ServerInfos" id={`server${Id}`}>
                <div className="ServerInfosContent col-sm-12">
                    <p><span>{Name}</span> / <span>{Description}</span></p>
                </div>
            </div>
        </div>
    )
}

export default Client
