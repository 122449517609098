import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import MainMenu from '../../menus/main/'

class Home extends Component {
    constructor( ) {
        super()
        this.state = {
            redirect: false,
            showProjectsList: false,
        }
    }

    componentDidMount() {
        if ( localStorage.getItem( 'api_token' ) ) {
            this.setState({ showProjectsList: true })
        } else {
            this.setState({ redirect: true })
        }
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        if ( this.state.showProjectsList ) {
            return ( <Redirect to="/projects" />)
        }
        return (
            <>
                <MainMenu />
            </>
        )
    }
}

export default Home
