import React from 'react';

const PhpInfos = (props) => {
    let PhpCurrentVersion = props.infos.php_current_version
    let PhpCurrentVersionStatus = props.infos.php_current_version_status
    let PhpCurrentVersionClass = 'alert-info'
    if ( PhpCurrentVersionStatus === 'OK' ) {
        PhpCurrentVersionClass = 'alert-success'
    }
    if ( PhpCurrentVersionStatus === 'DANGER' ) {
        PhpCurrentVersionClass = 'alert-danger'
    }
    return (
        <p className="phps">
            <span className={`php text-center alert ${ PhpCurrentVersionClass }`}>{ PhpCurrentVersion }</span>
        </p>
    );
};

export default PhpInfos