import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Axios from 'axios'

import MainMenu from '../../menus/main/'

import InputText from '../../components/input/text/'
import InputButton from '../../components/input/button/'

require('dotenv').config()

class TecnhoAdd extends Component {
    constructor( ) {
        super()
        this.state = {
            name: '',
            technoAdded: false,
            errors: [],
            redirect: false,
        }
    }

    componentDidMount() {
        if ( ! localStorage.getItem( 'api_token' ) ) {
            this.setState({ redirect: true })
        }
    }

    handleChangeName = (event) =>  {
        this.setState({ name : event.target.value })
    }

    handleSubmit = (event) => {
        event.preventDefault()

        let bodyFormData = new FormData();
        bodyFormData.set( 'name' , this.state.name )

        let headers = {
            headers: {
                'API-TOKEN' : localStorage.getItem( 'api_token' )
            }
        }

        Axios.post( process.env.REACT_APP_API_URL + '/api/techno', bodyFormData, headers )
        .then(res => {
            if ( res.data.success ) {
                this.setState({ technoAdded: true })
            }
        })
        .catch(errors =>  {
            if ( errors.response.status ) {
                this.setState({ errors: errors.response.data.errors }, () => { console.warn( this.state.errors )})
            }
        });
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        if ( this.state.technoAdded ) {
            return ( <Redirect to="/technos" />)
        }
        return (
            <>
                <MainMenu currentPage="Technos" />
                <div className="Technos TecnhoAdd animate__animated animate__bounceInRight">
                    <div className="container">
                        <h2 className="my-5">Ajouter une nouvelle techno</h2>
                        <form method="POST" onSubmit={this.handleSubmit} className="text-start">
                            <InputText
                                htmlId='TechnoInputName'
                                label='Nom de cette nouvelle techno'
                                onChange={this.handleChangeName}
                                errors={this.state.errors.name}
                            />
                            { this.state.errors && this.state.errors.code === 'bad_credentials' ? <div className="alert alert-danger" >{ this.state.errors.msg }</div> : '' }
                            <InputButton label='Ajouter' />
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default TecnhoAdd
