import React from 'react';

const DateInfos = (props) => {
    let DateInfos = props.infos.created_at
    return (
        <p className="dates">
            <span className={`date text-center alert `}>{ DateInfos }</span>
        </p>
    );
};

export default DateInfos