import React, { Component } from 'react'
import Axios from 'axios'
import Select from 'react-select'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Popup from 'reactjs-popup'

import Loading from '../../components/loading/'

import ProjectInfos from '../projectinfos/projectinfos'

import "./modal.scss"

require('dotenv').config()

class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            openModal: true,
            limit: 10,
            moreDetails: []
        }
    }

    componentDidMount() {
        this.setState({ showLoading: true }, () => {
            this.getMoreDetails()
        })
    }

    getMoreDetails = () => {
        if (localStorage.getItem('api_token')) {
            let headers = {
                headers: {
                    'API-TOKEN': localStorage.getItem('api_token')
                }
            }
            Axios.get(process.env.REACT_APP_API_URL + '/api/projectinfos/' + this.props.infos.project_id + '?limit=' + this.state.limit, headers)
                .then(res => {
                    this.setState({ moreDetails: res.data.success, showLoading: false })
                })
                .catch(errors => {
                    if (errors.response.status === 403) {
                        localStorage.setItem('api_token', '')
                        localStorage.clear()
                        this.setState({ redirect: true })
                    } else {
                        console.warn(errors.response)
                    }
                })
        } else {
            this.setState({ redirect: true })
        }
    }

    onLimitFiltersChange = (event) => {
        this.setState({ limit: event.value, showLoading: false }, () => {
            this.getMoreDetails()
        })
    }

    closeModal = () => {
        this.setState({ openModal: false }, () => {
            this.props.showMoreInfos()
        })
    }

    render() {
        return (
            <>
                <Popup open={this.state.openModal} closeOnDocumentClick onClose={this.closeModal}>
                    <div className="modal">
                        <button onClick={this.closeModal} className="close">&times;</button>
                        <div className={`content text-start`}>
                            <FormGroup className="LimitFilters" >
                                <FormControlLabel
                                    control={<Select
                                        className="LimitFiltersSelect"
                                        name="LimitFiltersSelect"
                                        placeholder="Limit"
                                        options={
                                            [
                                                { value: '10', label: '10', className: 'option-limit' },
                                                { value: '50', label: '50', className: 'option-limit' },
                                                { value: '100', label: '100', className: 'option-limit' },
                                                { value: '150', label: '150', className: 'option-limit' },
                                                { value: '200', label: '200', className: 'option-limit' },
                                                { value: '500', label: '500', className: 'option-limit' },
                                                { value: '1000', label: '1000', className: 'option-limit' },
                                            ]
                                        }
                                        onChange={this.onLimitFiltersChange}
                                        simpleValue
                                        clearable={false}
                                    />}
                                    label="Limit"
                                />
                            </FormGroup>
                            <div className="ProjectInfos" >
                                <div className="row" >
                                    <div className="ProjectInfosContent col-sm-12">
                                        { this.state.showLoading && <Loading />}
                                        {
                                            ( this.state.moreDetails && this.state.moreDetails.length > 0 ) && this.state.moreDetails.map( (infos, index ) =>
                                                <ProjectInfos key={index} index={index} infos={infos} serverInfo={this.props.serverInfo} />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default Modal;
