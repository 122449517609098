import React from 'react'

import DateInfos from './dateinfos.js'
import GitInfos from './gitinfos.js'
import TechnoInfos from './technoinfos.js'
import PhpInfos from './phpinfos.js'
import RobotsTxtInfos from './robotstxtinfos.js'
import BackupInfos from './backupinfos.js'

const ProjectInfos = (props) => {
    return (
        <div className='text-start'>
            <DateInfos infos={props.infos} />
            <GitInfos infos={props.infos} />
            <TechnoInfos infos={props.infos} />
            <PhpInfos infos={props.infos} />
            <RobotsTxtInfos infos={props.infos} />
            <BackupInfos
                index={props.index}
                infos={props.infos}
                serverInfo={props.serverInfo}
            />
        </div>
    )
}

export default ProjectInfos;
