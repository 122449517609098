import React from 'react'

import Auth from './components/oauth/Auth.js'
import Home from './pages/home'
import Login from './pages/login'
import Register from './pages/register'
import Clients from './pages/clients'
import Technos from './pages/technos'
import TechnoAdd from './pages/technos/new'
import Servers from './pages/servers'
import ServerAdd from './pages/servers/new'
import Projects from './pages/projects'
import ProjectAdd from './pages/projects/new'

import { Switch, Route } from 'react-router-dom'

class AppRouter extends React.Component {
    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route path="/login/:provider/:token" component={Auth} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/clients" component={Clients} />
                    <Route exact path="/technos" component={Technos} />
                    <Route exact path="/techno/new" component={TechnoAdd} />
                    <Route exact path="/servers" component={Servers} />
                    <Route exact path="/server/new" component={ServerAdd} />
                    <Route exact path="/projects" component={Projects} />
                    <Route exact path="/project/new" component={ProjectAdd} />
                </Switch>
            </>
        )
    }
}

export default AppRouter
