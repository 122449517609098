import React from 'react';

const RobotsTxtInfos = (props) => {
    let RobotsTxtStatus = props.infos.robots_txt_status
    let RobotsTxtStatusClass = 'alert-danger'
    if ( RobotsTxtStatus === 'Robots.txt Ok' ) {
        RobotsTxtStatusClass = 'alert-success'
    }
    return (
        <p className="robots">
            <span className={`robot text-center alert ${ RobotsTxtStatusClass }`}>{ RobotsTxtStatus }</span>
        </p>
    );
};

export default RobotsTxtInfos