import React from 'react'

import './server.scss'

const Server = (props) => {
    let Id = props.server.id
    let OvhName = props.server.ovh_name
    let InouitName = props.server.inouit_name
    let Ip = props.server.ip
    let Type = props.server.type
    let OsName = props.server.os_name
    return (
        <div className="w-100 m-0 mb-4 p-0">
            <div className="ServerInfos" id={`server${Id}`}>
                <div className="ServerInfosContent col-sm-12">
                    <p><span>{OvhName}</span> / <span>{InouitName}</span> / <span>{Ip}</span> / <span>{Type}</span>/ <span>{OsName}</span></p>
                </div>
            </div>
        </div>
    )
}

export default Server
