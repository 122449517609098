import React from 'react';

const GitInfos = (props) => {
    let GitBranch = props.infos.git_current_branch
    let GitBranchClass = (GitBranch !== 'master' ? 'alert-danger' : 'alert-success')
    let GitTag = props.infos.git_current_tag
    let GitTagClass = (GitTag === 'v?.?.?' ? 'alert-danger' : 'alert-success')
    return (
        <p className="gits">
            <span className={`git text-center alert ${ GitBranchClass }`}>{ GitBranch }</span>
            <span className={`git text-center alert ${ GitTagClass }`}>{ GitTag }</span>
        </p>
    );
};

export default GitInfos