import React, { Component } from 'react'
import Axios from 'axios'
import { Redirect, Link } from 'react-router-dom'

import Loading from '../../components/loading/'

import MainMenu from '../../menus/main/'
import Techno from '../../components/techno/index.js'

import './technos.scss'

require('dotenv').config()

class Technos extends Component {
    constructor( ) {
        super()
        this.state = {
            showLoading: false,
            redirect: false,
            technos: [],
        }
    }

    componentDidMount() {
        this.setState({ showLoading: true })
        if ( localStorage.getItem( 'api_token' ) ) {
            let headers = {
                headers: {
                    'API-TOKEN' : localStorage.getItem( 'api_token' )
                }
            }
            Axios.get( process.env.REACT_APP_API_URL + '/api/technos', headers )
            .then( res => {
                this.setState({ technos: res.data.success, showLoading: false })
            })
            .catch( errors => {
                if ( errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirect: true })
                } else {
                    console.warn( errors.response )
                }
            })
        } else {
            this.setState({ redirect: true })
        }
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        return (
            <>
                { this.state.showLoading && <Loading /> }
                <MainMenu currentPage="Technos" />
                <div className="Technos animate__animated animate__bounceInRight">
                    <div className="container">
                        <Link className="btn btn-primary mb-5" to="/techno/new">Ajouter une nouvelle techno</Link>
                        {
                            this.state.technos.map( (techno) =>
                                <Techno key={techno.id} name={techno.name} />
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default Technos
