import React from 'react'

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const Arrows = (props) => {
    return (
        <>
            <span className="showMore" onClick={props.showMoreInfos}>{ props.showMore ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }</span>
        </>
    )
}

export default Arrows;
