import React from 'react'

import './inputtext.scss'

const InputText = (props) => {
    let { htmlId, label, onChange, errors, password } = props
    return (
        <>
            <div className="mb-3">
                <label htmlFor={htmlId} className="form-label">{label}</label>
                <input type={ password ? 'password' : 'text' } className={`form-control ${ errors  ? 'is-invalid' : ''}`} id={htmlId} onChange={onChange} />
                { errors ? <div className="text-danger invalid-feedback" >{ errors }</div> : '' }
            </div>
        </>
    )
}

export default InputText
