import React from 'react'

import './techno.scss'

const Techno = (props) => {
    let { id, name } = props
    return (
        <div className="w-100 m-0 mb-4 p-0">
            <div className="TecnhoInfos" id={`tecnho${id}`}>
                <div className="TecnhoInfosContent col-sm-12">
                    <p><span>{name}</span></p>
                </div>
            </div>
        </div>
    )
}

export default Techno
