import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Axios from 'axios'

import MainMenu from '../../menus/main/'

import InputText from '../../components/input/text/'
import InputButton from '../../components/input/button/'

require('dotenv').config()

class ServerAdd extends Component {
    constructor( ) {
        super()
        this.state = {
            ovh_name: '',
            inouit_name: '',
            ip: '',
            type: '',
            os_name: '',
            serverAdded: false,
            errors: [],
            redirect: false,
            api_token: null
        }
    }

    componentDidMount() {
        if ( ! localStorage.getItem( 'api_token' ) ) {
            this.setState({ redirect: true })
        }
    }

    handleChangeOvhName = (event) =>  {
        this.setState({ ovh_name : event.target.value })
    }

    handleChangeInouitName = (event) =>  {
        this.setState({ inouit_name : event.target.value })
    }

    handleChangeIp = (event) =>  {
        this.setState({ ip : event.target.value })
    }

    handleChangeType = (event) =>  {
        this.setState({ type : event.target.value })
    }

    handleChangeOsName = (event) =>  {
        this.setState({ os_name : event.target.value })
    }

    handleSubmit = (event) => {
        event.preventDefault()

        let bodyFormData = new FormData();
        bodyFormData.set( 'ovh_name' , this.state.ovh_name )
        bodyFormData.set( 'inouit_name' , this.state.inouit_name )
        bodyFormData.set( 'ip' , this.state.ip )
        bodyFormData.set( 'type' , this.state.type )
        bodyFormData.set( 'os_name' , this.state.os_name )

        let headers = {
            headers: {
                'API-TOKEN' : localStorage.getItem( 'api_token' )
            }
        }

        var endPoint = process.env.REACT_APP_API_URL + '/api/server'
        Axios.post( endPoint, bodyFormData, headers )
        .then(res => {
            if ( res.data.success ) {
                this.setState({ serverAdded: true })
            }
        })
        .catch(errors =>  {
            if ( errors.response.status ) {
                this.setState({ errors: errors.response.data.errors }, () => { console.warn( this.state.errors )})
            }
        });
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        if ( this.state.serverAdded ) {
            return ( <Redirect to="/servers" />)
        }
        return (
            <>
                <MainMenu currentPage="Servers" />
                <div className="Servers ServerAdd animate__animated animate__bounceInRight">
                    <div className="container">
                        <h2 className="my-5">Ajouter un nouveau server</h2>
                        <form method="POST" onSubmit={this.handleSubmit} className="text-start">
                            <InputText
                                htmlId='ServerInputOvhName'
                                label='Nom du server chez OVH'
                                onChange={this.handleChangeOvhName}
                                errors={ this.state.errors.ovh_name }
                            />
                            <InputText
                                htmlId='ServerInputInouitName'
                                label='Nom du server chez Inouit'
                                onChange={this.handleChangeInouitName}
                                errors={ this.state.errors.inouit_name }
                            />
                            <InputText
                                htmlId='ServerInputIp'
                                label='IP'
                                onChange={this.handleChangeIp}
                                errors={ this.state.errors.ip }
                            />
                            <InputText
                                htmlId='ServerInputType'
                                label='Type de serveur (Physique / VPS)'
                                onChange={this.handleChangeType}
                                errors={ this.state.errors.type }
                            />
                            <InputText
                                htmlId='ServerInputOsName'
                                label='Nom de OS du server'
                                onChange={this.handleChangeOsName}
                                errors={ this.state.errors.os_name }
                            />
                            { this.state.errors && this.state.errors.code === 'bad_credentials' ? <div className="alert alert-danger" >{ this.state.errors.msg }</div> : '' }
                            <InputButton label='Ajouter' />
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default ServerAdd
