import React, { Component } from 'react'
import Axios from 'axios'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import './filters.scss'

require('dotenv').config()

const SELECTS = require('../datas/Selects')

class ProjectFilters extends Component {
    constructor( props ) {
        super( props )
        this.state = {
            redirect: false,
            technos: [],
        }
    }

    componentDidMount() {
        if ( localStorage.getItem( 'api_token' ) ) {
            let headers = {
                headers: {
                    'API-TOKEN' : localStorage.getItem( 'api_token' )
                }
            }
            Axios.get( process.env.REACT_APP_API_URL + '/api/technos', headers )
            .then( res => {
                this.setState({ technos: res.data.success })
            })
            .catch( errors => {
                if ( errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirect: true })
                } else {
                    console.warn( errors.response )
                }
            })
        } else {
            this.setState({ redirect: true })
        }
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        var technos = SELECTS['technos']
        if ( this.state.technos && this.state.technos.length > 0 ) {
            technos = [{value: '', label: 'Toutes les technos', className: 'option-technos'}]
            this.state.technos.forEach( (techno) => {
                const tech = {value: techno.id, label: techno.name, className:'option-technos'};
                technos.push( tech )
            })
        }
        return (
			<FormGroup className="ProjectFilters" >
                <FormControlLabel
                    control={<Select
                        className="ProjectFiltersSelect"
                        name="ProjectFiltersTechnos"
                        placeholder="Toutes les technos"
                        options={technos}
                        onChange={this.props.onTechnosFiltersChange}
                        simpleValue
                        clearable={false}
                    />}
                    label="Filter par techno"
                />
                <FormControlLabel
                    control={<Switch
                        checked={this.props.phpCheckFilter}
                        size="small"
                        onChange={this.props.onPhpFiltersChange}
                        name="phpStatus"
                    />}
                    label="Php Critique"
                />
                <FormControlLabel
                    control={<Switch
                        checked={this.props.gitCheckFilter}
                        size="small"
                        onChange={this.props.onGitFiltersChange}
                        name="noGit"
                    />}
                    label="Projet sans git"
                />
                <FormControlLabel
                    control={<Switch
                        checked={this.props.gitTagCheckFilter}
                        size="small"
                        onChange={this.props.onGitTagFiltersChange}
                        name="noGitTag"
                    />}
                    label="Projet sans tag"
                />
			</FormGroup>
        )
    }
}

export default ProjectFilters
